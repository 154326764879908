var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchPanel',{attrs:{"search-params":_vm.searchParamsObject,"showMultipleSelect":true},on:{"update:searchParams":function (val, key, index) { _vm.searchParamsObject[index].value = val },"button-event":_vm.searhPanelButtonEvent}}),_c('b-card',[_c('MainActionPanel',{attrs:{"buttonLeftOptions":_vm.buttonLeftOptions,"buttonRightOptions":_vm.buttonRightOptions},on:{"main-action":_vm.mainActionEvent}}),_c('vxe-table',{ref:"xTable",attrs:{"border":"","auto-resize":true,"data":_vm.tableData,"highlight-current-row":"","cell-class-name":_vm.cellClassName,"loading":_vm.loading,"scroll-x":{enabled: true},"scroll-y":{enabled: true}}},[_c('vxe-table-column',{attrs:{"field":"id","title":"ID","width":"80"}}),_c('vxe-table-column',{attrs:{"field":"title","title":"新闻标题","width":"220","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"categoryName","title":"新闻分类","width":"220","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"pic","title":"新闻封面","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('viewer',[_c('b-img',{staticClass:"d-inline-block",attrs:{"src":row.pic,"height":"35","width":"auto"}})],1)]}}])}),_c('vxe-table-column',{attrs:{"field":"content","title":"新闻内容","min-width":"250","show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{domProps:{"textContent":_vm._s(row.content.replace(/<\/?.+?\/?>/g, '').replace(/&nbsp;/g, ''))}})]}}])}),_c('vxe-table-column',{attrs:{"field":"status","title":"状态","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{staticClass:"px-1 py-25",attrs:{"pill":"","variant":row.status ? 'light-primary' : 'light-warning'}},[_vm._v(" "+_vm._s(row.status ? '启用' : '停用')+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"action","title":"操作","align":"center","width":"160","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('table-action-panel',{attrs:{"rowName":row.id,"extraData":row,"menu":_vm.setMenuDisabled(row, 'status')},on:{"click-event":_vm.tableActionEvent}})]}}])})],1),_c('page-set-panel',{attrs:{"page":_vm.searchParams.page,"size":_vm.searchParams.size,"total":_vm.total},on:{"update:page":function($event){return _vm.$set(_vm.searchParams, "page", $event)},"update:size":function($event){return _vm.$set(_vm.searchParams, "size", $event)}}})],1),_c('SidebarPanel',{attrs:{"blankEditParams":_vm.blankEditParams,"editValue":_vm.editValue,"title":"新闻","layConfig":2},on:{"edit-event":_vm.editEvent},model:{value:(_vm.isShowSidebarActive),callback:function ($$v) {_vm.isShowSidebarActive=$$v},expression:"isShowSidebarActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }