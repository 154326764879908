import request from '@/auth/jwt/request'

/**
 * @description 网站新闻 -- 网站新闻列表
 * @param {Object} param params {Object} 传值参数
 */
export const newsListApi = params => { return request('website-news/list', 'get', params) }

/**
 * @description 网站新闻 -- 新闻分类下拉
 * @param {Object} param params {Object} 传值参数
 */
export const newsCateSelectApi = params => { return request('website-news-category/select', 'get', params) }

/**
 * @description 网站新闻 -- 增加修改网站新闻
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const newsSaveApi = data => { return request('website-news/save', 'post', {}, data) }

/**
 * @description 网站新闻 -- 新闻状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const newsStatusApi = params => { return request('website-news/status', 'get', params) }

/**
 * @description 网站新闻 -- 新闻详情
 * @param {Object} param params {Object} 传值参数
 */
export const newsInfoApi = params => { return request('website-news/info', 'get', params) }
/**
 * @description 网站新闻 -- 删除新闻
 * { id = x }
 */
export const newsDeleteApi = data => { return request('website-news/delete', 'delete', data) }